import { openDB } from 'idb';

const dbPromise = openDB(process.env.VUE_APP_PRODUCT_DB_NAME, 1, {
    upgrade(dbPromise) {
        dbPromise.createObjectStore('products');
        dbPromise.createObjectStore('lineItems');
        dbPromise.createObjectStore('syncInfo');
    }
});

export default {
    async all(storeName) {
        return (await dbPromise).getAll(storeName);
    },
    async get(storeName, key) {
        return (await dbPromise).get(storeName, key);
    },
    async set(storeName, key, val) {
        return (await dbPromise).put(storeName, val, key);
    },
    async del(storeName, key) {
        return (await dbPromise).delete(storeName, key);
    },
    async clear(storeName) {
        return (await dbPromise).clear(storeName);
    },
    async allKeys(storeName) {
        return (await dbPromise).getAllKeys(storeName);
    },
    async sync(storeName, data) {
        const tx = (await dbPromise).transaction(storeName, 'readwrite');
        let values = [];

        for (let key in data) {
            values.push(tx.store.put(data[key], key));
        }

        values.push(tx.done)
        await Promise.all(values);
    }
}
