import axios from 'axios';
import store from "../store";

export const getClient = ({apiUrl, headers} = {}) => {
  const _apiUrl = process.env.VUE_APP_API_URL;
  const _headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "sw-context-token" : "",
    "sw-access-key": process.env.VUE_APP_SW_ACCESS_KEY,
  };

  if (process.env.VUE_APP_ENV === 'dev') {
    _headers['auth'] = {
      "username": process.env.VUE_APP_AUTH_BASIC_USERNAME,
      "password": process.env.VUE_APP_AUTH_BASIC_PASSWORD
    };
  }

  if (store.getters['auth/isLoggedIn']) {
    _headers['sw-context-token'] = store.getters['auth/getContextToken'];
  }

  const options = {
    baseURL: apiUrl || _apiUrl,
    headers: headers || _headers
  };

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => requestConfig,
    (requestError) => {
      return Promise.reject(requestError);
    },
  );

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    (error) => {
      return Promise.reject(error);
    },
  );

  return client;
}

/**
 * Base HTTP Client
 */
export default {
  // Provide request methods with the default base_url
  get(url, conf = {}) {
    return getClient().get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  delete(url, conf = {}) {
    return getClient().delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  head(url, conf = {}) {
    return getClient().head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  options(url, conf = {}) {
    return getClient().options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  post(url, data = {}, conf = {}) {
    return getClient().post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  put(url, data = {}, conf = {}) {
    return getClient().put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  patch(url, data = {}, conf = {}) {
    return getClient().patch(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
