import IdbService from './idb.service';

export default {
    async getProductSyncInfo() {
        return await this.getSyncInfo('product');
    },

    async setProductSyncInfo(val) {
        return await this.setSyncInfo('product', val);
    },

    async deleteProductSyncInfo() {
        return await this.setSyncInfo('product', {});
    },

    async getSyncInfo(key) {
        return await IdbService.get('syncInfo', key);
    },

    async setSyncInfo(key, val) {
        return await IdbService.set('syncInfo', key, val);
    }
}
