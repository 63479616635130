import ApiService from '@/services/api.service';
import { CUSTOMER_INFO_API_PATH, CUSTOMER_SALES_CHANNEL } from "@/constants/api.constant";
import router from "@/router";
import CartService from '@/services/cart.service';

export default {
    async getCustomer({ commit, state }) {
        try {
            if (state.isLoggedIn) {
                const response = await ApiService.post(CUSTOMER_INFO_API_PATH, {
                    pwa: true
                  });
                commit('setCustomer', response.data);
                await CartService.updateCartUserId(response.data.id);
            } else {
                commit('setDefaultCustomer');
            }
        } catch (e) {
            commit('setDefaultCustomer');
            const currentRoute = router.currentRoute.value;
            if (currentRoute.meta.requiredAuth) {
                await router.push({name: 'login'});
            }
        }
    },
    async getSalesChannel({ commit }) {
        try {
            const response = await ApiService.get(CUSTOMER_SALES_CHANNEL);
            commit('setSalesChannel', response.data.salesChannel);
        } catch (e) {
            commit('setDefaultCustomer');
            const currentRoute = router.currentRoute.value;

            if (currentRoute.meta.requiredAuth) {
                await router.push({name: 'login'});
            }
        }
    },
}
