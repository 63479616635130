import ProductService from '@/services/product.service';
import SyncInfoService from "@/services/syncInfo.service";
import LoadingScreenService from "@/services/loadingScreen.service";
import loadingScreenService from "@/services/loadingScreen.service";

export default {
    init() {
        this.sync();
    },

    async shouldSyncedData() {
        const productSyncInfo = await SyncInfoService.getProductSyncInfo(); // {hash: "xxx"}
        const products = await ProductService.getProductsFromIdb();

        // Should sync if products doesn't exist in Idb
        if (!products || products.length === 0) {
            return true;
        }

        const hash = (productSyncInfo && productSyncInfo.hash) ? productSyncInfo.hash : false;
        const response = await ProductService.downloadProductJsonFile(1, true);

        // There are no product json files in server so don't need to do more
        if (response === null) {
            return false;
        }

        // The product json files were re-generated.
        return hash !== response.hash;
    },

    async sync() {
        const should = await this.shouldSyncedData();

        if (should === false) {
            return;
        }

        if (window.confirm("Product data was updated. Do you want to re-sync?")) {
            const loading = await LoadingScreenService.presentLoading();

            try {
                await ProductService.deleteProductsFromIdb();
                await ProductService.syncAllProductsToIdb();
            } catch (e) {
                console.log(e);
            }

            loadingScreenService.stopLoading(loading);
        }
    }
}
