const LOGIN_API_PATH = 'store-api/account/login';
const REGISTER_API_PATH = 'store-api/account/register';
const LOGOUT_API_PATH = 'store-api/account/logout';

// Customer
const CUSTOMER_INFO_API_PATH = 'store-api/account/customer';
const CUSTOMER_SALES_CHANNEL = 'store-api/context';
const CUSTOMER_DEFAULT_SHIPPING_ADDRESS_API_PATH = (id) => `store-api/account/address/default-shipping/${id}`;
const CUSTOMER_DEFAULT_BILLING_ADDRESS_API_PATH = (id) => `store-api/account/address/default-billing/${id}`;
const CUSTOMER_DEFAULT_SHIPPING_METHOD_API_PATH = (id) => `store-api/account/change-shipping-method/${id}`;

// Context
const CONTEXT_API_PATH = 'store-api/context';

// Product
const PRODUCT_DETAIL_API_PATH = (id) => `product/json/${id}`;
const PRODUCT_SEARCH_API_PATH = `store-api/product`;
const B2B_CUSTOM_PRODUCT_NUMBER_API = '/store-api/b2b/product/customNo';

// Addresses
const CUSTOMER_ADDRESSES_API_PATH = `store-api/account/list-address`;

// Shipping
const CUSTOMER_SHIPPING_API_PATH = `store-api/shipping-method?onlyAvailable=true`;

// Cart
const CHECKOUT_CART_API_PATH = `store-api/checkout/cart`;
const CHECKOUT_CART_LINE_ITEMS_API_PATH = `store-api/checkout/cart/line-item`;
const CHECKOUT_CART_VALIDATE_API_PATH = 'store-api/chekout/b2b/cart/validate';

// Order
const CHECKOUT_ORDER_API_PATH = `store-api/checkout/order`;
const CONVERT_SHOP_ORDER_TO_B2B_ORDER =  'store-api/b2b-order-convert';
const CHECKOUT_B2B_ORDER_CREATE =  'store-api/b2b-order-create';

//Offer
const CHECKOUT_B2B_OFFER_CREATE_API = '/store-api/b2b/offer/create';

export {
  LOGIN_API_PATH,
  REGISTER_API_PATH,
  LOGOUT_API_PATH,
  CUSTOMER_INFO_API_PATH,
  PRODUCT_DETAIL_API_PATH,
  PRODUCT_SEARCH_API_PATH,
  CUSTOMER_ADDRESSES_API_PATH,
  CUSTOMER_SALES_CHANNEL,
  CUSTOMER_SHIPPING_API_PATH,
  CHECKOUT_CART_API_PATH,
  CHECKOUT_CART_LINE_ITEMS_API_PATH,
  CHECKOUT_ORDER_API_PATH,
  CUSTOMER_DEFAULT_SHIPPING_METHOD_API_PATH,
  CUSTOMER_DEFAULT_BILLING_ADDRESS_API_PATH,
  CUSTOMER_DEFAULT_SHIPPING_ADDRESS_API_PATH,
  CONTEXT_API_PATH,
  CONVERT_SHOP_ORDER_TO_B2B_ORDER,
  CHECKOUT_CART_VALIDATE_API_PATH,
  CHECKOUT_B2B_OFFER_CREATE_API,
  CHECKOUT_B2B_ORDER_CREATE,
  B2B_CUSTOM_PRODUCT_NUMBER_API
}
