import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/store';
import {DEFAULT_AUTH_ROUTE} from "@/constants/route.constant";

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/shopping-cart',
    name: 'shopping-cart',
    component: () => import('../views/ShoppingCart.vue')
  },
  {
    path: '/scanned-product/free',
    name: 'scanned-product-free',
    meta: {
      requiredScannedProduct: true,
    },
    component: () => import('../views/scanned-product/FreeProduct.vue')
  },
  {
    path: '/scanned-product',
    name: 'scanned-product',
    meta: {
      requiredScannedProduct: true,
    },
    component: () => import('../views/scanned-product/ScannedProduct.vue')
  },
  {
    path: '/scanned-product/not-found',
    name: 'scanned-product-not-found',
    component: () => import('../views/scanned-product/NoProduct.vue')
  },
  {
    path: '/scan-qr',
    name: 'scan-qr',
    component: () => import('../views/ScanQr.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },{
    path: '/checkout/addresses',
    name: 'addresses',
    meta: {
      requiredAuth: true,
    },
    component: () => import('../views/checkout/Addresses.vue')
  },{
    path: '/checkout/shipping',
    name: 'shipping',
    meta: {
      requiredAuth: true,
    },
    component: () => import('../views/checkout/Shipping.vue')
  },{
    path: '/checkout/summary',
    name: 'summary',
    meta: {
      requiredAuth: true,
    },
    component: () => import('../views/checkout/Summary.vue')
  },{
    path: '/checkout/finish',
    name: 'finish',
    component: () => import('../views/checkout/Finish.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters['auth/isLoggedIn'] && to.name === 'login') {
    next({name: DEFAULT_AUTH_ROUTE});

    return;
  }

  if (to.matched.some(record => record.meta.requiredAuth)) {
    // Check authenticated
    if (!store.getters['auth/isLoggedIn']) {
      localStorage.setItem('redirectAfterLogin', to.name);
      next({ name: 'login' });

      return;
    }
  }

  if (to.matched.some(record => record.meta.requiredScannedProduct)) {
    // Check scanned product existed
    if (!store.getters['product/getScannedProduct']) {
      next({ name: 'scan-qr' });

      return;
    }
  }

  next();
});

export default router
