import IdbService from './idb.service';
import SyncInfoService from "@/services/syncInfo.service";

export default {
    async getProductFromIdb(id) {
        return await IdbService.get('products', id);
    },

    async setProductToIdb(product) {
        const exist = await this.getProductFromIdb(product.id);

        if (!exist) {
            await IdbService.set('products', product.id, product);
        }
    },

    async getProductsFromIdb() {
        return await IdbService.all('products');
    },

    async deleteProductsFromIdb() {
        return await IdbService.clear('products');
    },

    async downloadProductJsonFile(page, forceCache = true) {
        const url =  process.env.VUE_APP_PRODUCT_JSON_URL + 'json/products-'+ page +'.json';
        const options = forceCache ? {cache: "no-store"} : {};
        const response = await fetch(url, options);

        if (response.status !== 200) {
            return null;
        }

        try {
            return await response.json();
        } catch (e) {
            return null;
        }
    },

    async syncAllProductsToIdb() {
        // Insert 1st page
        let response = await this.syncProductNthPageToIdb(1);

        if (response === null) {
            return;
        }

        // Insert n-th page
        for (let i = 2; i <= response.totalPage; i++) {
            await this.syncProductNthPageToIdb(i);
        }

        const info = {
            'hash': response.hash
        };
        await SyncInfoService.setProductSyncInfo(info);
    },

    async syncProductNthPageToIdb(page) {
        let response = await this.downloadProductJsonFile(page);

        if (response === null) {
            return null;
        }

        await IdbService.sync('products', response.data);

        return response;
    }
}
