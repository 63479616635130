import {
    TOKEN_KEY
} from '@/constants/auth.constant';

export default {
    setCustomer(state, payload) {
        state.customer = payload;
    },

    setSalesChannel(state, payload) {
        state.salesChannel = payload;
    },

    setContextToken(state, token) {
        state.contextToken = token;
        state.isLoggedIn = true;
        localStorage.setItem(TOKEN_KEY, token);
    },

    removeAuth(state) {
        state.customer = null;
        state.contextToken = null;
        state.isLoggedIn = null;
        localStorage.removeItem(TOKEN_KEY);
    },

    setNetworkStatus(state, status) {
        state.networkStatus = status;
    },

    setDefaultCustomer(state) {
        state.customer = {
            id : 0
        }
    }
}
