/* eslint-disable no-console */

import { register } from 'register-service-worker';
import SyncProducts from "@/service-workers/sync-products";

if (process.env.NODE_ENV === 'production') {
  register('service-worker.js', {
    ready: function () {
      console.log('service worker is ready');
    },
    registered () {
      console.log('Service worker has been registered.');

      SyncProducts.init();
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New version available! OK to refresh');
      if (window.confirm("New version available! OK to refresh?")) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload(true);
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  });
}
