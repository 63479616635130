export default {
  setScannedProduct(state, product) {
    state.scannedProduct = product;
  },
  setFreeProductRequest(state, message) {
    state.freeProductRequest = message;
  },
  clearScannedProduct(state) {
    state.scannedProduct = null;
    state.freeProductRequest = "";
  }
}
