import {loadingController} from "@ionic/vue";

export default {
    async presentLoading() {
        const loading = await loadingController.create({
            cssClass: 'pwa-loading-class',
            message: 'Syncing products data...',
            duration: 4 * 60000
        });

        await loading.present();

        return loading;
    },

    stopLoading(loading) {
        loading.dismiss();
    }
}
