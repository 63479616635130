<template>
  <ion-app>
    <ion-router-outlet :animated="true" />
  </ion-app>
</template>

<script lang="javascript">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import "@/theme/base.css";
import {mapMutations} from "vuex";

export default defineComponent({
  name: 'App',

  components: {
    IonApp,
    IonRouterOutlet
  },

  created() {

    document.title = '1A Medizintechnik - PWA';

    this.setNetworkStatus(window.navigator.onLine ? 'online' : 'offline');

    window.addEventListener('online', () => {
      this.setNetworkStatus('online');
    });

    window.addEventListener('offline', () => {
      this.setNetworkStatus('offline');
    });
  },

  methods: {
    ...mapMutations('auth', ['setNetworkStatus'])
  }
});
</script>
