import IdbService from './idb.service';

export default {
    async getLineItemsFromIdb(userId) {
        const data =  await IdbService.get('lineItems', userId);
        if (data) {
            return data;
        } else {
            return [];
        }
    },
    async getLineItemById(userId, lineItemId) {
        const lineItems = await this.getLineItemsFromIdb(userId);
        return lineItems.find(item => {
           return item.referencedId === lineItemId;
        });
    },

    async queryLineItem(userId, lineItem) {
        const all = await this.getLineItemsFromIdb(userId);
        return all.find(item => {
            return item.referencedId === lineItem.referencedId
                && item.freeArticleRequestMessage === lineItem.freeArticleRequestMessage;
        })
    },

    async addLineItemQuantity(userId, lineItem) {

        const lineItems = await this.getLineItemsFromIdb(userId);
        const inputItem = { ...lineItem };
        const exist = await this.queryLineItem(userId, inputItem);

        if (!exist || exist.length < 1) {
            lineItems.push(inputItem);
            await IdbService.set('lineItems', userId, JSON.parse(JSON.stringify(lineItems)));
            return;
        } else {
            for (let i = 0; i < lineItems.length; i++) {
                if (lineItems[i].id === inputItem.id) {
                    lineItems[i].quantity += parseInt(inputItem.quantity);
                }
            }
            await IdbService.set('lineItems', userId, JSON.parse(JSON.stringify(lineItems)));
        }
    },

    async setLineItem(userId, lineItem) {
        const lineItems = await this.getLineItemsFromIdb(userId);
        const exist = await this.queryLineItem(userId, lineItem);
        const inputItem = { ...lineItem };
        if (!exist || exist.length < 1) {
            lineItems.push(inputItem);
            await IdbService.set('lineItems', userId, JSON.parse(JSON.stringify(lineItems)));
            return;
        } else {
            for (let i = 0; i < lineItems.length; i++) {
                if (lineItems[i].id === inputItem.id) {
                    lineItems[i].quantity = parseInt(inputItem.quantity);
                }
            }
            await IdbService.set('lineItems', userId, JSON.parse(JSON.stringify(lineItems)));
        }
    },

    async removeLineItem(userId, lineItemId) {
        let newLineItems = [];
        const lineItems = await this.getLineItemsFromIdb(userId);

        for (let i = 0; i < lineItems.length; i++) {
            if (lineItems[i].id !== lineItemId) {
                newLineItems.push(lineItems[i]);
            }
        }

        await IdbService.set('lineItems', userId, JSON.parse(JSON.stringify(newLineItems)));
    },

    async clearCart (userId) {
        await IdbService.del('lineItems', userId);
    },

    async updateCartUserId(userId) {
        if (userId !== 0 ) {
            const lineItems = await this.getLineItemsFromIdb(0);
            if (lineItems && lineItems.length > 0) {
                await IdbService.set('lineItems', userId, JSON.parse(JSON.stringify(lineItems)));
                await this.clearCart(0);
            }
        }
    }
}
